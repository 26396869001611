<template>
    <div class="analysis-view">
        <Tabs
            style="margin-bottom: 1.4vw"
            background="#f9f9f9"
            v-model:active="selectDimension"
            :ellipsis="false"
            @change="onDimensionChange"
        >
            <Tab title="业务成本分析" name="node"></Tab>
            <Tab title="服务成本分析" name="service"></Tab>
            <Tab title="细分计费分析" name="detail"></Tab>
            <Tab title="地域成本分析" name="region"></Tab>
            <Tab title="云商成本分析" name="cloud"></Tab>
            <!-- <Tab title="标签成本分析" name="tag"></Tab> -->
        </Tabs>
        <div class="chart-panel">
            <ChartBox
                name="成本详情"
                @mounted="renderCostDetail"
                :loading="costDetailLoading"
            ></ChartBox>
        </div>
        <div class="chart-panel">
            <ChartBox
                name="成本差额"
                @mounted="renderCostDiff"
                :loading="costDiffLoading"
            ></ChartBox>
        </div>
        <div class="chart-panel">
            <ChartBox
                name="成本占比"
                @mounted="renderCostRatio"
                :loading="costRatioLoading"
            ></ChartBox>
        </div>
        <div class="chart-panel">
            <ChartBox
                name="成本同环比"
                @mounted="renderCostCompareDiffChart"
                :loading="costCompareDiffLoading"
            ></ChartBox>
        </div>
    </div>
</template>

<style lang="less" scoped>
.analysis-view {
    position: relative;

    .chart-panel {
        height: 70vw;
        border: 1px solid #eee;
        margin-bottom: 4vw;
    }
}
</style>

<script setup>
import { ref, h } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Button, Tab, Tabs } from 'vant';

import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { useDateRange } from '@/common/QueryDateRange';
import { formatNumber } from '@/common/tools';
import {
    useModel,
    useNodes,
    useTag,
    useFilters,
    useGranularity,
    formatAxisData,
    aliasRegionLabel,
    useRouteWatcher,
} from '../../../../CostTree/Cost/components/tools';
import { AdvancedTooltip, yAxisLabelFormatter } from '@/common/EChartsTools';

import ChartBox from '@/components/ChartBox';
import MobTooltip from './MobTooltip';

import { MVBizViewAPI } from '@/common/API';

const TOOLTIP_MAXHEIGHT = '36vw';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    BarChart,
    UniversalTransition,
]);

const route = useRoute();
const router = useRouter();

const treeType = useModel(route);
const nodes = useNodes(route);
const granularity = useGranularity(route);
const tag = useTag(route);
const filter = useFilters(route);
const dateRange = useDateRange(route);

let selectDimension = ref('node');

function onDrillDown(nodeName) {
    router.push({
        params: {
            name: `${route.params.name}>${nodeName}`,
        },
        query: route.query,
    });
}

/**
 * 成本详情
 */

let costDetailChartInstance = null;

function renderCostDetail(dom) {
    costDetailChartInstance = echarts.init(dom);
}

let costDetailLoading = ref(false);
let costDetailData = null;
// 成本趋势：line；堆叠占比：bar；
let costDetailChartType = ref('line');
let costDetailChartTooltip = new AdvancedTooltip();

function drawCostDetailChart() {
    let legends = Object.keys(costDetailData);
    let axisData = Object.keys(costDetailData[legends[0]]);
    costDetailChartInstance.setOption({
        grid: {
            top: 20,
            left: 50,
            right: 14,
            bottom: 30,
        },
        tooltip: costDetailChartTooltip.tooltip({
            maxHeight: TOOLTIP_MAXHEIGHT,
            valueFormatter: value => formatNumber(value),
            seriesItemRatio: { show: costDetailChartType.value === 'bar' },
            seriesTotal: { show: costDetailChartType.value === 'bar' },
            vNodeCreator: (params, option) =>
                h(
                    MobTooltip,
                    { data: params, option },
                    selectDimension.value === 'node'
                        ? {
                              itemSuffix: slotProps => (
                                  <Button
                                      size="mini"
                                      type="primary"
                                      style="margin-left:8px;margin-bottom:2px"
                                      onClick={() =>
                                          onDrillDown(
                                              slotProps.series.seriesName
                                          )
                                      }
                                  >
                                      下钻
                                  </Button>
                              ),
                          }
                        : null
                ),
            opt: {
                appendToBody: false,
                position: { top: 0, right: 0 },
                backgroundColor: 'rgba(255,255,255,0.5)',
            },
        }),
        xAxis: {
            type: 'category',
            data: formatAxisData(axisData, granularity.value),
        },
        yAxis: {
            type: 'value',
            ...yAxisLabelFormatter(),
        },
        series: legends.map(key => ({
            name:
                selectDimension.value === 'region'
                    ? aliasRegionLabel(key)
                    : key,
            type: costDetailChartType.value,
            ...(costDetailChartType.value === 'bar' ? { stack: 'total' } : {}),
            showSymbol: false,
            data: axisData.map(
                date =>
                    costDetailData[key][date] &&
                    +costDetailData[key][date].toFixed(2)
            ),
            triggerLineEvent: true,
        })),
    });
}

function loadCostDetail() {
    costDetailLoading.value = true;
    costDetailChartInstance && costDetailChartInstance.clear();
    MVBizViewAPI.getViewData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    }).then(res => {
        costDetailLoading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        costDetailData = res.data;
        drawCostDetailChart();
    });
}

/**
 * 成本差额
 */

let costDiffChartInstance = null;
let costDiffChartTooltip = new AdvancedTooltip();
let costDiffIsYOY = ref(true);
let costDiffValueType = ref('diff');
let costDiffLoading = ref(false);

function renderCostDiff(dom) {
    costDiffChartInstance = echarts.init(dom);
}

function loadCostDiff() {
    costDiffLoading.value = true;
    costDiffChartInstance && costDiffChartInstance.clear();
    let unit = costDiffValueType.value;
    let cycle = costDiffIsYOY.value
        ? { day: 7, week: 4, month: 12 }[granularity.value] || 7
        : 1;
    MVBizViewAPI.getAnalysisData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        cycle,
        unit,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            costDiffLoading.value = false;
            if (res.error_no !== 0) {
                return;
            }
            let resData = res.data;
            let legends = Object.keys(resData);
            let axisData = Object.keys(resData[legends[0]]);
            costDiffChartInstance.setOption({
                grid: {
                    top: 20,
                    left: 50,
                    right: 14,
                    bottom: 30,
                },
                tooltip: costDiffChartTooltip.tooltip({
                    maxHeight: TOOLTIP_MAXHEIGHT,
                    valueFormatter: value =>
                        unit === 'diff'
                            ? formatNumber(value)
                            : `${(value * 100).toFixed(2)}%`,
                    vNodeCreator: (params, option) =>
                        h(
                            MobTooltip,
                            { data: params, option },
                            selectDimension.value === 'node'
                                ? {
                                      itemSuffix: slotProps => (
                                          <Button
                                              size="mini"
                                              type="primary"
                                              style="margin-left:8px;margin-bottom:2px"
                                              onClick={() =>
                                                  onDrillDown(
                                                      slotProps.series
                                                          .seriesName
                                                  )
                                              }
                                          >
                                              下钻
                                          </Button>
                                      ),
                                  }
                                : null
                        ),
                    opt: {
                        appendToBody: false,
                        position: { top: 0, right: 0 },
                        backgroundColor: 'rgba(255,255,255,0.5)',
                    },
                }),
                xAxis: {
                    type: 'category',
                    data: formatAxisData(axisData, granularity.value),
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: value =>
                            unit === 'diff'
                                ? formatNumber(value, 0)
                                : `${(value * 100).toFixed(2)}%`,
                    },
                },
                series: legends.map(key => ({
                    name:
                        selectDimension.value === 'region'
                            ? aliasRegionLabel(key)
                            : key,
                    type: 'line',
                    showSymbol: false,
                    data: axisData.map(
                        date =>
                            resData[key][date] &&
                            +resData[key][date].toFixed(unit === 'diff' ? 2 : 4)
                    ),
                    areaStyle: {},
                    triggerLineEvent: true,
                })),
            });
        })
        .catch(err => {
            costDiffLoading.value = false;
            console.log(err);
        });
}

/**
 * 成本占比
 */

let costRatioLoading = ref(false);
let costRatioChartInstance = null;

function renderCostRatio(dom) {
    costRatioChartInstance = echarts.init(dom);
}

function loadCostRatio() {
    costRatioLoading.value = true;
    costRatioChartInstance && costRatioChartInstance.clear();
    MVBizViewAPI.getViewData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            costRatioLoading.value = false;
            if (res.error_no !== 0) {
                return;
            }

            let total = 0;
            let list = Object.keys(res.data).map(key => {
                let value = Object.values(res.data[key])[0];
                total += value;
                return {
                    name: key,
                    value,
                };
            });

            list = list.map(item => ({ ...item, percent: item.value / total }));
            list.sort((a, b) => -1 * (a.percent - b.percent));
            // costRatioTableData.value = list;

            let newList = [];
            let ignoreList = [];
            let ignoreValue = 0;
            let totalPercent = 0;
            list.forEach(item => {
                if (totalPercent > 0.95) {
                    ignoreList.push(item);
                    ignoreValue += item.value;
                    return;
                }
                newList.push(item);
                totalPercent += item.percent;
            });
            if (ignoreList.length > 2) {
                newList.push({
                    name: '[其他]',
                    value: ignoreValue,
                    percent: 1 - totalPercent,
                });
            } else {
                newList.push(...ignoreList);
            }

            costRatioChartInstance.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        return `
                        ${params.seriesName}
                        <div style="display:flex;flex-direction:row;align-items:center;">
                            ${params.marker}
                            <div>${params.name}&nbsp;&nbsp;<span style="font-weight:bold;">${params.value} (${params.percent}%)</span></div>
                        </div>
                    `;
                    },
                    position: { top: 0, right: 0 },
                    backgroundColor: 'rgba(255,255,255,0.5)',
                },
                series: [
                    {
                        name: '成本占比',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: '70%',
                        data: newList.map(item => ({
                            name:
                                selectDimension.value === 'region'
                                    ? aliasRegionLabel(item.name)
                                    : item.name,
                            value: +item.value.toFixed(2),
                        })),
                    },
                ],
            });
        })
        .catch(err => {
            console.log(err);
            costRatioLoading.value = false;
        });
}

/**
 * 成本同环比
 */

let costCompareDiffLoading = ref(false);
let costCompareDiffChartInstance = null;
let costCompareDiffChartTooltip = new AdvancedTooltip();
let costCompareIsYOY = ref(true);
let costCompareValueType = ref('diff');

function renderCostCompareDiffChart(dom) {
    costCompareDiffChartInstance = echarts.init(dom);
}

function loadCostCompareDiff() {
    costCompareDiffLoading.value = true;
    costCompareDiffChartInstance && costCompareDiffChartInstance.clear();
    let unit = costCompareValueType.value;
    let cycle = costCompareIsYOY.value
        ? { day: 7, week: 4, month: 12 }[granularity.value] || 7
        : 1;
    MVBizViewAPI.getAnalysisData({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: selectDimension.value,
        granularity: granularity.value,
        cycle,
        unit,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    })
        .then(res => {
            costCompareDiffLoading.value = false;
            if (res.error_no !== 0) {
                return;
            }
            let resData = res.data;
            let legends = Object.keys(resData);
            let vk = Object.keys(resData[legends[0]])[0];
            let seriesData = legends.map(name => ({
                name,
                value: resData[name][vk],
            }));
            seriesData.sort((a, b) => -1 * (a.value - b.value));
            costCompareDiffChartInstance.setOption({
                grid: {
                    top: 20,
                    left: 50,
                    right: 14,
                    bottom: 30,
                },
                tooltip: costCompareDiffChartTooltip.tooltip({
                    maxHeight: TOOLTIP_MAXHEIGHT,
                    valueFormatter: value =>
                        unit === 'diff'
                            ? formatNumber(value)
                            : `${(value * 100).toFixed(2)}%`,
                    vNodeCreator: (params, option) =>
                        h(
                            MobTooltip,
                            { data: params, option },
                            selectDimension.value === 'node'
                                ? {
                                      itemSuffix: slotProps => (
                                          <Button
                                              size="mini"
                                              type="primary"
                                              style="margin-left:8px;margin-bottom:2px"
                                              onClick={() =>
                                                  onDrillDown(
                                                      slotProps.series
                                                          .seriesName
                                                  )
                                              }
                                          >
                                              下钻
                                          </Button>
                                      ),
                                  }
                                : null
                        ),
                    opt: {
                        appendToBody: false,
                        position: { top: 0, right: 0 },
                        backgroundColor: 'rgba(255,255,255,0.5)',
                    },
                }),
                xAxis: {
                    type: 'category',
                    data: [costCompareIsYOY.value ? '同比' : '环比'],
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: value =>
                            unit === 'diff'
                                ? formatNumber(value, 0)
                                : `${(value * 100).toFixed(2)}%`,
                    },
                },
                series: seriesData.map(item => ({
                    type: 'bar',
                    name:
                        selectDimension.value === 'region'
                            ? aliasRegionLabel(item.name)
                            : item.name,
                    data: [
                        item.value &&
                            +item.value.toFixed(unit === 'diff' ? 2 : 4),
                    ],
                })),
            });
        })
        .catch(err => {
            costCompareDiffLoading.value = false;
            console.log(err);
        });
}

// function setCostCompareYOY(isYOY) {
//     if (isYOY === costCompareIsYOY.value) {
//         return;
//     }
//     costCompareIsYOY.value = isYOY;
//     loadCostCompareDiff();
//     Log.click(
//         `cost_view_analysis_show_compare_diff_is_${isYOY ? 'yoy' : 'chain'}`
//     );
// }

// function setCostCompareValueType(type) {
//     if (type === costCompareValueType.value) {
//         return;
//     }
//     costCompareValueType.value = type;
//     loadCostCompareDiff();
//     Log.click('cost_view_analysis_show_compare_diff_' + type);
// }

function loadData() {
    loadCostDetail();
    loadCostDiff();
    loadCostRatio();
    loadCostCompareDiff();
}

function onDimensionChange() {
    MVBizViewAPI.abortViewAndAnalysisRequest();
    loadData();
}

loadData();

useRouteWatcher(route)(() => {
    if (route.params.name === '_default') {
        return;
    }
    loadData();
});
</script>
