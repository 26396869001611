<template>
    <div class="cost-trend-view">
        <ChartBox name="成本趋势" @mounted="renderCostTrend"></ChartBox>
    </div>
</template>

<style lang="less" scoped>
.cost-trend-view {
    height: 70vw;
    border: 1px solid #eee;
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import * as echarts from 'echarts/core';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import ChartBox from '@/components/ChartBox';
import { formatNumber } from '@/common/tools';
import {
    useModel,
    useNodes,
    useGranularity,
    useTag,
    useRouteWatcher,
    useFilters,
    formatAxisData,
} from '../../../../CostTree/Cost/components/tools';
import { useDateRange } from '@/common/QueryDateRange';
import { yAxisLabelFormatter } from '@/common/EChartsTools';

import { MVBizViewAPI } from '@/common/API';

const route = useRoute();

const dateRange = useDateRange(route);

const treeType = useModel(route);

const nodes = useNodes(route);

const granularity = useGranularity(route);

const tag = useTag(route);

const filter = useFilters(route);

echarts.use([
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    UniversalTransition,
    TooltipComponent,
]);

let trendChart = null;

function renderCostTrend(dom) {
    trendChart = echarts.init(dom);
}

let loading = ref(false);

function loadData() {
    loading.value = true;
    trendChart && trendChart.clear();
    let cycle = { day: 7, week: 4, month: 12 }[granularity.value] || 7;
    MVBizViewAPI.getCostContrast({
        ...dateRange.value,
        tree_type: treeType.value,
        node: nodes.value,
        dimension: 'all',
        cycle,
        granularity: granularity.value,
        ...(tag.value ? { tag: tag.value } : {}),
        ...filter.value,
    }).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        let axisData = Object.keys(res.data.base);
        let localSeriesData = Object.values(res.data.base);
        let compareSeriesData = Object.values(res.data.contrast);
        trendChart.setOption({
            grid: {
                top: 20,
                left: 50,
                right: 14,
                bottom: 30,
            },
            tooltip: {
                trigger: 'axis',
                valueFormatter: value => formatNumber(value),
                position: { top: 0, right: 0 },
                backgroundColor: 'rgba(255,255,255,0.5)',
            },
            xAxis: {
                type: 'category',
                data: formatAxisData(axisData, granularity.value),
            },
            yAxis: {
                type: 'value',
                min: value => +(value.min - value.min / 10).toFixed(),
                ...yAxisLabelFormatter(),
            },
            series: [
                {
                    name: '成本趋势',
                    data: localSeriesData.map(item => item && +item.toFixed(2)),
                    type: 'line',
                    showSymbol: false,
                },
                {
                    name: '成本同比',
                    data: compareSeriesData.map(
                        item => item && +item.toFixed(2)
                    ),
                    type: 'line',
                    showSymbol: false,
                },
            ],
        });
    });
}

loadData();

useRouteWatcher(route)(() => {
    if (route.params.name === '_default') {
        return;
    }
    loadData();
});
</script>
