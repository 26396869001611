<template>
    <div class="filter-view">
        <div class="nav-bar" @click="showPopup">
            <Icon name="bars"></Icon>
            <div class="name">{{ route.params.name }}</div>
        </div>
        <Popup
            v-model:show="isFilterPopupShow"
            position="top"
            :style="{ width: '100%' }"
        >
            <div class="content">
                <div class="title">模块树设置</div>
                <Field
                    v-model="treeName"
                    label="模块树"
                    is-link
                    readonly
                    @click="showTreeNameActionSheet"
                ></Field>
                <div class="title">模块设置</div>
                <Field
                    v-model="node"
                    label="模块路径"
                    is-link
                    readonly
                    @click="showNodeSearchView"
                ></Field>
                <Field
                    v-model="dateText"
                    label="日期区间"
                    is-link
                    readonly
                    @click="showDateTypePicker"
                ></Field>
                <Field
                    v-model="granularityText"
                    label="粒度"
                    is-link
                    readonly
                    @click="showGranularityPicker"
                ></Field>
                <!-- <Field v-model="dateText" label="标签" is-link></Field>
                <Field v-model="dateText" label="Service" is-link></Field>
                <Field v-model="dateText" label="细分计费" is-link></Field>
                <Field v-model="dateText" label="地域" is-link></Field>
                <Field v-model="dateText" label="云商" is-link></Field>
                <Field v-model="dateText" label="RI开关" is-link></Field>
                <Field
                    v-model="dateText"
                    label="SavingPlan未使用开关"
                    is-link
                ></Field> -->
            </div>
            <div class="submit">
                <Button type="primary" block square @click="onQueryClick"
                    >查询</Button
                >
            </div>
        </Popup>
        <!-- 模块树切换选择器 -->
        <ActionSheet
            v-model:show="isTreeNameActionShow"
            :actions="treeNameActions"
            @select="onTreeNameSelect"
        ></ActionSheet>
        <!-- 模块路径选择器 -->
        <Popup
            v-model:show="isNodeSearchShow"
            position="bottom"
            :style="{ width: '100%' }"
            :overlay="false"
        >
            <div class="node-search">
                <Sticky>
                    <Form action="/">
                        <Search
                            v-model="nodeQuery"
                            shape="round"
                            placeholder="请输入模块名称关键字"
                            show-action
                            @search="onQuery"
                            @cancel="onQueryCancel"
                        ></Search>
                    </Form>
                </Sticky>
                <div class="res-title">
                    {{ nodeQuery === '' ? '猜你想看' : '搜索结果' }}
                </div>
                <List>
                    <Cell
                        v-for="item in resultNodes"
                        :key="item.key"
                        :title="item.label"
                        :label="item.key"
                        @click="onQuerySelect(item)"
                    />
                </List>
            </div>
        </Popup>
        <!-- 日期区间选择器 -->
        <Popup v-model:show="isDateTypePickerShow" round position="bottom">
            <Picker
                v-model="dateType"
                :columns="dateTypeOpts"
                @confirm="onDatePicked"
                @cancel="isDateTypePickerShow = false"
            ></Picker>
        </Popup>
        <!-- 粒度选择器 -->
        <Popup v-model:show="isGranularityPickerShow" round position="bottom">
            <Picker
                v-model="granularity"
                :columns="granularityOptions"
                @confirm="onGranularityPicked"
                @cancel="isGranularityPickerShow = false"
            ></Picker>
        </Popup>
    </div>
</template>

<style lang="less" scoped>
@import '../../../../../common/common.less';

.filter-view {
    margin: 0;
    padding: 2vw;
    background-color: @primary-color;

    .nav-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;

        .name {
            margin-left: 2vw;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 1vw 2vw 1vw 0;
            font-size: 3.8vw;
            line-height: 4vw;
        }
    }

    .content {
        background-color: #f7f7f7;
        padding: 1vw 2vw 10vw;
        height: 50vh;
        overflow-y: auto;

        .title {
            font-size: 4vw;
            font-weight: bold;
            padding: 3vw 1vw;
        }
    }

    .node-search {
        height: 100vh;

        .res-title {
            margin-left: 3.4vw;
            color: #999;
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs';

import {
    Button,
    Popup,
    Field,
    Form,
    Picker,
    ActionSheet,
    Search,
    List,
    Cell,
    Sticky,
    Icon,
    showToast,
} from 'vant';

import { MVBizViewAPI } from '@/common/API';
import { quickRangeMap } from '@/common/QueryDateRange';
import UserTreeModel from '../../../../CostTree/Cost/components/UserTreeModel';

const route = useRoute();
const router = useRouter();

let isFilterPopupShow = ref(false);

function showPopup() {
    initFilterValues();
    loadData();
    isFilterPopupShow.value = true;
}

let treeName = ref('');

let treeNameActions = ref([]);

let isTreeNameActionShow = ref(false);

function showTreeNameActionSheet() {
    if (treeNameActions.value.length < 1) {
        showToast('模块树选项数据异常');
        return;
    }
    isTreeNameActionShow.value = true;
}

function onTreeNameSelect(item) {
    isFilterPopupShow.value = false;
    isTreeNameActionShow.value = false;
    UserTreeModel.set(item.name);
    router.replace({
        params: {
            model: item.name,
            name: 'MV',
        },
    });
}

let node = ref('');

let isNodeSearchShow = ref(false);

function showNodeSearchView() {
    isNodeSearchShow.value = true;
    filteredNodes.value = [];
}

function onQueryCancel() {
    isNodeSearchShow.value = false;
}

let nodeQuery = ref('');

let queryBaseNodes = ref([]);
let filteredNodes = ref([]);
let recommendNodes = ref([]);

const resultNodes = computed(() => {
    if (filteredNodes.value.length > 0) {
        return filteredNodes.value;
    }
    return recommendNodes.value;
});

function genTreeNodes(nodes, layer = '') {
    return nodes.map(item => {
        let node = {
            label: item.name,
            key: layer + (layer ? '>' : '') + item.name,
            leaf: !(item.children && item.children.length > 0),
        };
        queryBaseNodes.value.push({ ...node });
        if (layer.indexOf('>') < 0) {
            recommendNodes.value.push({ ...node });
        }
        if (item.children && item.children.length > 0) {
            node.children = genTreeNodes(item.children, node.key);
        }
        return node;
    });
}

function onQuery(keyword) {
    setTimeout(() => {
        filteredNodes.value = queryBaseNodes.value.filter(
            item => item.key.toLowerCase().indexOf(keyword.toLowerCase()) > -1
        );
    }, 100);
}

function onQuerySelect(item) {
    node.value = item.key;
    isNodeSearchShow.value = false;
    nodeQuery.value = '';
    filteredNodes.value = [];
}

const quickMap = quickRangeMap();
let quickItems = Object.keys(quickMap).map(key => ({ key, ...quickMap[key] }));

let dateType = ref(['last7']);
let dateRes = computed(() => ({ type: dateType.value[0] }));

let dateText = computed(() => {
    let item = quickItems.find(item => item.key === dateType.value[0]);
    return item ? item.label : '最近7天';
});

let dateTypeOpts = ref(
    quickItems.map(item => ({ text: item.label, value: item.key }))
);

let isDateTypePickerShow = ref(false);

function showDateTypePicker() {
    isDateTypePickerShow.value = true;
}

function onDatePicked({ selectedValues }) {
    isDateTypePickerShow.value = false;
}

let granularity = ref(['day']);

const granularityText = computed(() => {
    return granularityOptions.value.find(
        item => item.value === granularity.value[0]
    ).text;
});

let granularityOptions = ref([
    { text: '日', value: 'day' },
    { text: '周', value: 'week' },
    { text: '相对周', value: '7' },
    { text: '月', value: 'month' },
]);

let isGranularityPickerShow = ref(false);

function showGranularityPicker() {
    isGranularityPickerShow.value = true;
}

function onGranularityPicked({ selectedValues }) {
    // granularity.value = selectedValues;
    isGranularityPickerShow.value = false;
}

function onQueryClick() {
    MVBizViewAPI.abortViewAndAnalysisRequest();
    let dateQuery = {};
    // 写route的规则：如果选择了相对时间区间，则只写入qbt值
    if (dateRes.value.type) {
        dateQuery.qdt = dateRes.value.type;
        // Log.click('cost_view_date_use_qdt_' + dateQuery.qdt);
    } else {
        dateQuery.start = dateRes.value.range[0];
        dateQuery.end = dateRes.value.range[1];
        // Log.click('cost_view_date_use_date');
    }
    router.replace({
        params: {
            name: node.value,
        },
        query: {
            ...dateQuery,
            granularity: granularity.value[0],
            t: dayjs().unix(),
        },
    });
    isFilterPopupShow.value = false;
}

function initFilterValues() {
    treeName.value = route.params.model;
    node.value = route.params.name;
    if (route.query.qdt) {
        dateType.value = [route.query.qdt];
        // dateRes.value = { type: route.query.qdt };
    }
    if (route.query.granularity) {
        granularity.value[0] = route.query.granularity;
    }
}

function loadTreeList() {
    MVBizViewAPI.getTreeList().then(res => {
        if (res.error_no !== 0) {
            console.log(res.error_msg);
            return;
        }
        treeNameActions.value = res.data.map(name => ({ name }));
    });
}

function loadTreeData() {
    MVBizViewAPI.getStructTree(treeName.value).then(res => {
        if (res.error_no !== 0) {
            return;
        }
        queryBaseNodes.value = [];
        recommendNodes.value = [];
        genTreeNodes(res.data);
    });
}

function loadData() {
    loadTreeList();
    loadTreeData();
}
</script>
