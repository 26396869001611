<template>
    <div
        v-if="
            route.params.model !== 'no_permission' &&
            route.params.model !== '_default'
        "
        class="m-cost-view"
    >
        <FilterView></FilterView>
        <div class="content">
            <CostTrend />
            <AnalysisView style="margin-top: 1vw" />
        </div>
    </div>
</template>

<style lang="less" scoped>
.m-cost-view {
    position: relative;

    .content {
        padding: 4vw 2vw;
        background-color: #f9f9f9;
    }
}
</style>

<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore, useGlobalStore } from '@/stores/global';

import { useRouteWatcher } from '../../../CostTree/Cost/components/tools';
import { isEmptyObject } from '@/common/tools';

import UserTreeModel from '../../../CostTree/Cost/components/UserTreeModel';

import FilterView from './components/FilterView';
import CostTrend from './components/CostTrend';
import AnalysisView from './components/AnalysisView';

const router = useRouter();

const route = useRoute();

const userStore = useUserStore();

const globalStore = useGlobalStore();

function getCurrentTreeModel() {
    return UserTreeModel.get() || globalStore.globalConfig.defaultTree;
}

function defaultName() {
    let nodeMap = {
        biz_bu_mv: 'MV',
        biz_bu_mtg: 'MV>MTG',
        biz_bu_reyun: 'MV>热云',
        biz_bu_others: 'MV>其他',
    };
    if (!isEmptyObject(globalStore.globalConfig.defaultBUNodeMapv2)) {
        nodeMap =
            globalStore.globalConfig.defaultBUNodeMapv2[
                getCurrentTreeModel()
            ] || {};
    }
    for (let key in nodeMap) {
        if (userStore.currentUser.roles.includes(key)) {
            return nodeMap[key];
        }
    }
    return 'MV';
}

function routerReplace() {
    router.replace({
        params: {
            model: getCurrentTreeModel(),
            name: defaultName(),
        },
    });
}

useRouteWatcher(route)(() => {
    if (route.params.model === '_default') {
        routerReplace();
    }
});

onMounted(() => {
    if (route.params.model === '_default') {
        routerReplace();
    }
});
</script>
